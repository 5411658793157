import PeopleIcon from "@material-ui/icons/People";
import PieChartIcon from "@material-ui/icons/PieChart";
import NotificationsIcon from "@material-ui/icons/Notifications";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import LinkIcon from "@material-ui/icons/Link";
import UsersList from "views/Users/UsersList.js";
import ReportsPage from "views/Reports/Reports.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import FirmwarePage from "views/Firmware/Firmware.js";

const dashboardRoutes = [
  {
    path: "/list-users",
    name: "Users",
    rtlName: "Users",
    icon: PeopleIcon,
    component: UsersList,
    layout: "/admin"
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "Reports",
    icon: PieChartIcon,
    component: ReportsPage,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "Notifications",
    icon: NotificationsIcon,
    component: NotificationsPage,
    layout: "/admin"
  },
  {
    path: "/firmware",
    name: "Firmware",
    rtlName: "Firmware",
    icon: BluetoothIcon,
    component: FirmwarePage,
    layout: "/admin"
  }
];

export default dashboardRoutes;
