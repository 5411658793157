import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import UsersTable from "components/UsersTable/UsersTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import api from "../../Services/Api";
import CustomInput from "components/CustomInput/CustomInput.js";
import isEmail from "validator/lib/isEmail";
import "./UsersList.scss";
import Search from "@material-ui/icons/Search";

export function isValidPassword(password) {
  return (
    password &&
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /\d/.test(password)
  );
}

const { innerWidth: width, innerHeight: height } = window;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  actionText: {
    "&:hover": {
      color: "#9c27b0",
      cursor: "pointer"
    },
    "&:not(:first-child)": {
      marginLeft: "10px"
    }
  },
  popin: {
    position: "fixed",
    zIndex: 1000,
    // top: 0,
    width,
    height,
    backgroundColor: "rgba(130, 130, 130, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    marginTop: "-100px",
    marginLeft: "-30px"
  },
  deletePopin: {
    width: "350px",
    backgroundColor: "#f2f2f2",
    borderRadius: "4px"
  },
  titlePopin: {
    margin: "15px 30px",
    color: "#9c27b0"
  },
  rowButton: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 45px 20px 45px"
  },
  updatePopin: {
    width: "350px",
    backgroundColor: "#f2f2f2",
    borderRadius: "4px"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  formWrapper: {
    width: "90%",
    margin: "0 5%"
  },
  alignMiddle: {
    margin: "0 auto"
  },
  filter: {
    marginRight: "20px"
  }
};

const useStyles = makeStyles(styles);
export default function UsersList() {
  const [joovvUsersData, setJoovvUsersData] = useState([]);

  const [deletePopin, setDeletePopin] = useState(false);
  const [updatePopin, setUpdatePopin] = useState(false);
  const [createPopin, setCreatePopin] = useState(false);
  const [passwordPopin, setPasswordPopin] = useState(false);
  const [usersNumber, setUsersNumber] = useState(0);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [userNames, setUserNames] = useState([]);

  const usersData = [];
  const classes = useStyles();

  const clearPopins = () => {
    setDeletePopin(false);
    setUpdatePopin(false);
    setCreatePopin(false);
    setPasswordPopin(false);
    setError("");
    setEmail("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const deleteCognitoUser = () => {
    setDisabled(true);
    api
      .deleteCognitoUser(userName, email)
      .then(() => {
        const index = joovvUsersData.findIndex(el => el[2] === email);
        joovvUsersData.splice(index, 1);
        clearPopins();
        setDisabled(false);
      })
      .catch(err => {
        setError(err.message);
        setDisabled(false);
      });
  };

  const prepareDeletePopin = (ev, username, email) => {
    ev.stopPropagation();
    setDeletePopin(true);
    setUserName(username);
    setEmail(email);
  };

  const buildActions = (userName, firstName, lastName, email) => (
    <span>
      <span
        className={classes.actionText}
        onClick={ev => prepareDeletePopin(ev, userName, email)}
      >
        Delete
      </span>
      <span
        className={classes.actionText}
        onClick={ev =>
          prepareUpdatePopin(ev, userName, firstName, lastName, email)
        }
      >
        Update
      </span>
      <span
        className={classes.actionText}
        onClick={ev => preparePasswordPopIn(ev, userName)}
      >
        Password
      </span>
    </span>
  );

  const updateCognitoUser = () => {
    if (!firstName || !lastName) {
      setError("First name and last name attributes are required");
      return;
    }
    setDisabled(true);
    api
      .updateCognitoUser({ username: userName, firstName, lastName })
      .then(res => {
        if (res.data.err) {
          let { message } = res.data.err;
          message = message.replace(" name", " First name");
          message = message.replace("family_name", "Last name");
          setDisabled(false);
          setEmail("");
          setError(message);
        } else {
          const index = joovvUsersData.findIndex(el => el[2] === email);
          joovvUsersData[index][0] = firstName;
          joovvUsersData[index][1] = lastName;
          joovvUsersData[index][3] = buildActions(
            userName,
            firstName,
            lastName,
            email
          );
          setDisabled(false);
          clearPopins();
        }
      })
      .catch(err => {
        setError(err.message);
        setDisabled(false);
      });
  };

  const prepareUpdatePopin = (ev, username, firstName, lastName, email) => {
    ev.stopPropagation();
    setUpdatePopin(true);
    setFirstName(firstName);
    setLastName(lastName);
    setUserName(username);
    setEmail(email);
  };

  const createCognitoUser = () => {
    if (!isEmail(email)) {
      setError("Email is not valid");
      return;
    }
    if (!firstName || !lastName) {
      setError("First name and last name attributes are required");
      return;
    }
    setDisabled(true);
    api
      .createCognitoUser({ email, firstName, lastName })
      .then(res => {
        if (res.data.err) {
          setError(res.data.err.message);
        } else {
          const userData = [];
          const { Attributes: userAttributes, Username } = res.data.result.User;

          const existLastName = userAttributes.find(
            el => el.Name === "family_name"
          );
          const lastName = existLastName ? existLastName.Value : "";
          const existFirstName = userAttributes.find(el => el.Name === "name");
          const firstName = existFirstName ? existFirstName.Value : "";
          const existEmail = userAttributes.find(el => el.Name === "email");
          const email = existEmail ? existEmail.Value : "";
          const actions = buildActions(Username, firstName, lastName, email);

          userData.push(firstName);
          userData.push(lastName);
          userData.push(email);
          userData.push(actions);
          usersData.push(userData);

          setJoovvUsersData([userData, ...joovvUsersData]);
          setUserNames([Username, ...userNames]);
          clearPopins();
        }
        setDisabled(false);
      })
      .catch(err => {
        setError(err.message);
        setDisabled(false);
      });
  };

  const prepareCreatePopin = () => {
    setCreatePopin(true);
  };

  const updatePassword = () => {
    if (!newPassword) {
      setError("Password is required");
      return;
    }
    if (!confirmNewPassword) {
      setError("Confirm password is required");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }
    if (!isValidPassword(newPassword)) {
      setError(
        "The password must be a minimum of 8 characters long, and must contain at least one digit, one lowercase character, and one uppercase character."
      );
      return;
    }
    setDisabled(true);
    api
      .updateCognitoUserPassword({
        username: userName,
        password: newPassword
      })
      .then(res => {
        if (res.data.err) {
          setError(res.data.err.message);
        } else {
          clearPopins();
        }
        setDisabled(false);
      })
      .catch(err => {
        setError(err.message);
        setDisabled(false);
      });
  };

  const preparePasswordPopIn = (ev, userName) => {
    ev.stopPropagation();
    setPasswordPopin(true);
    setUserName(userName);
  };

  const handleKeyPress = async event => {
    if (event.key === "Enter") {
      await getMoreUsers(true);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (error) {
      setError("");
    }
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "userFilter") {
      setUserFilter(value);
    }
    if (name === "newPassword") {
      setNewPassword(value);
    }
    if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const getMoreUsers = (fromFilter = false) => {
    let data;

    if (fromFilter) {
      data = { skip: 0 };
    } else {
      data = { skip: joovvUsersData.length };
    }
    if (userFilter) {
      data.filter = userFilter.trim();
    }
    api
      .getCognitoUsers(data)
      .then(res => {
        const { allUsers, noUsers } = res.data.result;
        const updatedUserNames = [];
        setUsersNumber(noUsers);
        allUsers.forEach(user => {
          const {
            email,
            name: firstName,
            familyName: lastName,
            username
          } = user;
          const userData = [];
          const actions = buildActions(username, firstName, lastName, email);
          updatedUserNames.push(username);      

          userData.push(firstName);
          userData.push(lastName);
          userData.push(email);
          userData.push(actions);
          usersData.push(userData);
        });

       
        if (fromFilter) {
          setJoovvUsersData(usersData);
          setUserNames(updatedUserNames);
          
        } else {
          setJoovvUsersData([...joovvUsersData, ...usersData]);    
          setUserNames([...userNames, ...updatedUserNames]);
        }
      })
      .catch(err => console.log("err", err));

  };

  useEffect(() => {
    async function populateTable() {
      await getMoreUsers();
    }

    populateTable().catch(err => console.log(err));
  }, []);

  const DeleteCognitoUser = (
    <div className={classes.deletePopin}>
      <p className={classes.titlePopin}>
        Are you sure you want to delete this user?
      </p>
      <div className={classes.rowButton}>
        <Button
          round
          color="danger"
          onClick={deleteCognitoUser}
          disabled={disabled}
        >
          Yes
        </Button>
        <Button round color="white" disabled={disabled} onClick={clearPopins}>
          No
        </Button>
      </div>
    </div>
  );

  const UpdateCognitoUser = (
    <div className={classes.updatePopin}>
      <div className={classes.formWrapper}>
        <CustomInput
          labelText="First name"
          id="firstName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "firstName",
            onChange: handleChange,
            defaultValue: firstName
          }}
        />
        <CustomInput
          labelText="Last name"
          id="lastName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "lastName",
            onChange: handleChange,
            defaultValue: lastName
          }}
        />
        {error && (
          <Danger>
            <p>{error}</p>
          </Danger>
        )}
      </div>
      <div className={classes.rowButton}>
        <Button
          round
          color="primary"
          disabled={disabled}
          onClick={updateCognitoUser}
        >
          Update
        </Button>
        <Button round color="white" disabled={disabled} onClick={clearPopins}>
          Cancel
        </Button>
      </div>
    </div>
  );

  const CreateCognitoUser = (
    <div className={classes.updatePopin}>
      <div className={classes.formWrapper}>
        <CustomInput
          labelText="Email"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "email",
            onChange: handleChange
          }}
        />
        <CustomInput
          labelText="First name"
          id="firstName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "firstName",
            onChange: handleChange
          }}
        />
        <CustomInput
          labelText="Last name"
          id="lastName"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "lastName",
            onChange: handleChange
          }}
        />
        {error && (
          <Danger>
            <p>{error}</p>
          </Danger>
        )}
      </div>
      <div className={classes.rowButton}>
        <Button
          round
          color="primary"
          disabled={disabled}
          onClick={createCognitoUser}
        >
          Create
        </Button>
        <Button round color="white" disabled={disabled} onClick={clearPopins}>
          Cancel
        </Button>
      </div>
    </div>
  );

  const UpdateCognitoUserPassword = (
    <div className={classes.updatePopin}>
      <div className={classes.formWrapper}>
        <CustomInput
          labelText="New Password"
          id="newPassword"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "password",
            name: "newPassword",
            onChange: handleChange,
            defaultValue: newPassword
          }}
        />
        <CustomInput
          labelText="Confirm New Password"
          id="confirmNewPassword"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "password",
            name: "confirmNewPassword",
            onChange: handleChange,
            defaultValue: confirmNewPassword
          }}
        />
        {error && (
          <Danger>
            <p>{error}</p>
          </Danger>
        )}
      </div>
      <div className={classes.rowButton}>
        <Button
          round
          color="primary"
          disabled={disabled}
          onClick={updatePassword}
        >
          Set Password
        </Button>
        <Button round color="white" disabled={disabled} onClick={clearPopins}>
          Cancel
        </Button>
      </div>
    </div>
  );

  const searchFilters = (
    <div className="filters">
      <CustomInput
        labelText="Search user"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          placeholder: "Search user",
          onChange: handleChange,
          name: "userFilter",
          onKeyPress: handleKeyPress,
          inputProps: {
            "aria-label": "Search"
          }
        }}
      />

      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        onClick={() => getMoreUsers(true)}
      >
        <Search />
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      {deletePopin && <div className={classes.popin}>{DeleteCognitoUser}</div>}
      {updatePopin && <div className={classes.popin}>{UpdateCognitoUser}</div>}
      {createPopin && <div className={classes.popin}>{CreateCognitoUser}</div>}
      {passwordPopin && (
        <div className={classes.popin}>{UpdateCognitoUserPassword}</div>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <div className={classes.tableHeader}>
                <div>
                  <h4 className={classes.cardTitleWhite}>User Management</h4>
                  <p className={classes.cardCategoryWhite}>
                    Manage users and see individual stats
                  </p>
                </div>
                <div>
                  <Button round color="white" onClick={prepareCreatePopin}>
                    Create new user
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {searchFilters}
              <UsersTable
                tableHeaderColor="primary"
                tableHead={["First name", "Last name", "Email", "Actions"]}
                tableData={joovvUsersData}
                userNames={userNames}
              />
            </CardBody>
          </Card>
        </GridItem>
        {usersNumber > joovvUsersData.length && (
          <Button
            round
            color="primary"
            size="lg"
            onClick={() => getMoreUsers()}
            disabled={disabled}
            className={classes.alignMiddle}
          >
            See more users
          </Button>
        )}
      </GridContainer>
    </React.Fragment>
  );
}
