import React, { useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
//core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/dropdownStyle.js";

const useStyles = makeStyles(styles);

export default function Dropdown({ label, error, data, onChange }) {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const handleToggle = event => {
    if (open && open.contains(event.target)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };

  const handleClose = index => {
    setOpen(null);
    if (typeof index === "number") {
      onChange(data[index]);
    }
  };

  return (
    <div className={classes.manager}>
      <Button
        round
        color={error ? "danger" : "white"}
        justIcon={false}
        aria-owns={open ? "menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ArrowDropDownIcon className={classes.icons} />
        <span className={classes.notifications}>{label}</span>
      </Button>
      <Poppers
        open={Boolean(open)}
        anchorEl={open}
        transition
        disablePortal
        className={classNames(
          { [classes.popperClose]: !open },
          classes.pooperNav
        )}
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  {data.map((item, index) => (
                    <MenuItem
                      key={item.label}
                      onClick={() => handleClose(index)}
                      className={classes.dropdownItem}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>
    </div>
  );
}
