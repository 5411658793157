import React, { useState, useEffect } from "react";
import Chartist from "chartist";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import ChartistGraph from "react-chartist";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoCard from "components/InfoCard/InfoCard.js";
import Button from "components/CustomButtons/Button.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import Danger from "components/Typography/Danger.js";
import useStyles from "../commonStyles.js";
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import api from "../../Services/Api";
import { DEVICE_MODELS } from "../../constants";

const DATE_FORMAT_PICKER = "M/D/Y";
const DATE_FORMAT_API = "YYYY-MM-DD";
const DATE_FORMAT_CHART_ITEM = "M/D";

const DEVICE_TYPE = {
  LIBERTA: "liberta",
  LEGACY: "legacy"
};

const DEVICE_MODEL_ALL = "all";
const DEVICE_MODEL_UNKNOWN = "unknown";

const DEFAULT_CHART_OPTIONS = {
  lineSmooth: Chartist.Interpolation.cardinal({
    tension: 0
  }),
  scaleMinSpace: 20,
  axisY: {
    onlyInteger: true
  },
  chartPadding: {
    top: 10,
    right: 0,
    bottom: 0,
    left: 0
  }
};

const useDashboardStyles = makeStyles(dashboardStyles);

const Reports = () => {
  const classes = useStyles();
  const dashClasses = useDashboardStyles();
  const [loadingUser, setLoadingUser] = useState(false);
  const [errorUser, setErrorUser] = useState("");
  const [userStats, setUserStats] = useState({
    total: null,
    active: null,
    newLastWeek: null,
    newLastMonth: null
  });
  const [loadingSession, setLoadingSession] = useState(false);
  const [errorSession, setErrorSession] = useState("");
  const [sessionStats, setSessionStats] = useState({
    total: null,
    durationSeconds: null,
    typeRed: null,
    typeIr: null,
    typeBoth: null
  });
  const [loadingDevice, setLoadingDevice] = useState(false);
  const [errorDevice, setErrorDevice] = useState("");
  const [deviceStats, setDeviceStats] = useState({
    total: null,
    byType: null
  });
  const [deviceModel, setDeviceModel] = useState(DEVICE_MODEL_ALL);
  const [loadingGoal, setLoadingGoal] = useState(false);
  const [errorGoal, setErrorGoal] = useState("");
  const [goalStats, setGoalStats] = useState({
    active: null,
    achievements: null
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [errorQuery, setErrorQuery] = useState("");
  const [queryStats, setQueryStats] = useState(null);
  const [queryStartTime, setQueryStartTime] = useState(null);
  const [queryEndTime, setQueryEndTime] = useState(null);

  useEffect(() => {
    fetchUsersStats();
    fetchSessionStats();
    fetchDevicesStats();
    fetchGoalsStats();
  }, []);

  const fetchUsersStats = (start, end) => {
    setLoadingUser(true);
    setErrorUser("");
    api.getUserStats(start, end).then(res => {
      setLoadingUser(false);
      if (res.ok) {
        setUserStats(res.data);
      } else {
        setErrorUser(`Could not fetch users ${res.problem} ${res.status}`);
      }
    });
  };

  const fetchSessionStats = (start, end) => {
    setLoadingSession(true);
    setErrorSession("");
    api.getSessionStats(start, end).then(res => {
      setLoadingSession(false);
      if (res.ok) {
        setSessionStats(res.data);
      } else {
        setErrorSession(
          `Could not fetch sessions ${res.problem} ${res.status}`
        );
      }
    });
  };

  const fetchDevicesStats = (start, end, model) => {
    setLoadingDevice(true);
    setErrorDevice("");
    api.getDeviceStats(start, end, model).then(res => {
      setLoadingDevice(false);
      if (res.ok) {
        setDeviceStats(res.data);
      } else {
        setErrorDevice(`Could not fetch devices ${res.problem} ${res.status}`);
      }
    });
  };

  const fetchGoalsStats = (start, end) => {
    setLoadingGoal(true);
    setErrorGoal("");
    api.getGoalStats(start, end).then(res => {
      setLoadingGoal(false);
      if (res.ok) {
        setGoalStats(res.data);
      } else {
        setErrorGoal(`Could not fetch goals ${res.problem} ${res.status}`);
      }
    });
  };

  const fetchChartStats = (start, end) => {
    setLoadingQuery(true);
    setErrorQuery("");
    api.getRangeStats(start, end).then(res => {
      setLoadingQuery(false);
      if (res.ok) {
        setQueryStartTime(startTime);
        setQueryEndTime(endTime);
        setQueryStats(res.data);
      } else {
        setQueryStats(null);
        setErrorQuery(`Could not query ${res.problem} ${res.status}`);
      }
    });
  };

  const handleQuery = () => {
    if (!startTime || !endTime) {
      setErrorQuery("Please select both start and end dates");
      return;
    }
    const start = startTime.format(DATE_FORMAT_API);
    const end = endTime.format(DATE_FORMAT_API);
    fetchChartStats(start, end);
    fetchUsersStats(start, end);
    fetchSessionStats(start, end);
    fetchDevicesStats(start, end, deviceModel);
    fetchGoalsStats(start, end);
  };

  const handleDeviceModel = model => {
    setDeviceModel(model);
    const start = queryStartTime && queryStartTime.format(DATE_FORMAT_API);
    const end = queryEndTime && queryEndTime.format(DATE_FORMAT_API);
    fetchDevicesStats(start, end, model);
  };

  const totalSessionTime = () => {
    const sessionMinutes = sessionStats.durationSeconds / 60;
    return sessionMinutes > 60
      ? `${Math.floor(sessionMinutes / 60)}h`
      : `${Math.floor(sessionMinutes)}mins`;
  };

  const mostPopularLight = () => {
    const { total, typeRed, typeIr, typeBoth, typeAmbient, typeRecovery } = sessionStats;
    if (total) {
      if (typeRed >= typeIr && typeRed >= typeBoth && typeRed >= typeAmbient && typeRed >= typeRecovery) {
        return "Red";
      }
      if (typeIr >= typeRed && typeIr >= typeBoth && typeIr >= typeAmbient && typeIr >= typeRecovery) {
        return "IR";
      }
      if (typeBoth >= typeRed && typeBoth >= typeIr && typeBoth >= typeAmbient && typeBoth >= typeRecovery) {
        return "Both";
      }
      if (typeAmbient >= typeRed && typeAmbient >= typeIr && typeAmbient >= typeBoth && typeAmbient >= typeRecovery) {
        return "Ambient";
      }
      if (typeRecovery >= typeRed && typeRecovery >= typeIr && typeRecovery >= typeAmbient && typeRecovery >= typeBoth) {
        return "Recovery+";
      }
    }
    // Ambient
    // Recovery+
    return "-";
  };

  const lightDistribution = () => {
    const { typeRed, typeIr, typeBoth, total } = sessionStats;
    if (total) {
      const red = Math.floor((typeRed / total) * 100);
      const ir = Math.floor((typeIr / total) * 100);
      const both = Math.floor((typeBoth / total) * 100);
      return `${ir}% of sessions IR, ${red}% Red, ${both}% Both`;
    }
    return `No sessions completed`;
  };

  const deviceTypes = () => {
    const { byType } = deviceStats;
    let libertas = 0;
    let legacies = 0;
    if (byType && byType.length) {
      const libertasElem = byType.find(e => e._id === DEVICE_TYPE.LIBERTA);
      if (libertasElem) {
        libertas = libertasElem.total;
      }
      const legaciesElem = byType.find(e => e._id === DEVICE_TYPE.LEGACY);
      if (legaciesElem) {
        legacies = legaciesElem.total;
      }
    }
    return `Libertas: ${libertas}, Legacy: ${legacies}`;
  };

  const chartDataFor = (variable, field) => {
    const dataMap = new Map();
    const arr = queryStats[variable];
    const start = moment(queryStartTime);
    const end = moment(queryEndTime);
    dataMap.set(start.format(DATE_FORMAT_CHART_ITEM), 0);
    while (start.isBefore(end, "day")) {
      start.add(1, "day");
      dataMap.set(start.format(DATE_FORMAT_CHART_ITEM), 0);
    }
    for (let i = 0; i < arr.length; i++) {
      const key = moment(arr[i].date, DATE_FORMAT_API).format(
        DATE_FORMAT_CHART_ITEM
      );
      dataMap.set(key, arr[i][field]);
    }
    return {
      labels: [...dataMap.keys()],
      series: [[...dataMap.values()]]
    };
  };

  const devicesChartData = () => {
    const additions = chartDataFor("devicesAdded", "total");
    const removals = chartDataFor("devicesRemoved", "total");
    return {
      labels: [...additions.labels],
      series: [[...additions.series[0]], [...removals.series[0]]]
    };
  };

  const sessionsChartData = () => chartDataFor("sessions", "total");
  const newUsersChartData = () => chartDataFor("newUsers", "total");
  const activeUsersChartData = () => chartDataFor("activeUsers", "total");
  const newDevicesChartData = () => devicesChartData();

  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Period Reporting</h4>
          <p className={classes.cardCategoryWhite}>
            Query stats for specific periods
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <DatePicker
                disableFuture
                maxDate={endTime || undefined}
                clearable
                label="From"
                inputVariant="outlined"
                format={DATE_FORMAT_PICKER}
                value={startTime}
                onChange={setStartTime}
                className={classes.dateInput}
              />
              <DatePicker
                disableFuture
                minDate={startTime || undefined}
                clearable
                label="To"
                inputVariant="outlined"
                format={DATE_FORMAT_PICKER}
                value={endTime}
                onChange={setEndTime}
                className={classes.dateInput}
              />
              <Button
                round
                size="lg"
                color="primary"
                onClick={handleQuery}
                loading={loadingQuery}
              >
                Query
              </Button>
            </GridItem>
            {errorQuery && (
              <GridItem xs={12}>
                <Danger>{errorQuery}</Danger>
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </Card>
      <GridItem xs={12}>
        <h3>{queryStats ? "Period" : "Global"} statistics</h3>
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingUser}
          error={errorUser}
          title={queryStats ? "Total users" : "Total / Active users"}
          value={
            queryStats
              ? `${userStats.total}`
              : `${userStats.total} / ${userStats.active}`
          }
          icon="people"
          color={queryStats ? undefined : "primary"}
          description={
            queryStats
              ? `Total users signed up before ${endTime.format(
                  DATE_FORMAT_CHART_ITEM
                )}`
              : "Active users have logged in the last 30 days"
          }
        />
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingUser}
          error={errorUser}
          title={queryStats ? "New users" : "New users this week"}
          value={
            queryStats ? `${userStats.newPeriod}` : `${userStats.newLastWeek}`
          }
          icon="group_add"
          color={queryStats ? undefined : "primary"}
          description={
            queryStats
              ? `Signed up between ${startTime.format(
                  DATE_FORMAT_CHART_ITEM
                )} and ${endTime.format(DATE_FORMAT_CHART_ITEM)}`
              : `${userStats.newLastMonth} new users in the last 30 days`
          }
        />
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingSession}
          error={errorSession}
          title="Total session time"
          value={totalSessionTime()}
          icon="schedule"
          color={queryStats ? undefined : "primary"}
          description={`${sessionStats.total} sessions completed`}
        />
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingSession}
          error={errorSession}
          title="Most popular light"
          value={mostPopularLight()}
          icon="flare"
          color={queryStats ? undefined : "primary"}
          description={lightDistribution()}
        />
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingDevice}
          error={errorDevice}
          title="Devices registered"
          value={`${deviceStats.total}`}
          icon="bluetooth"
          color={queryStats ? undefined : "primary"}
          description={deviceTypes()}
          footerChildren={
            <select
              value={deviceModel}
              onChange={ev => handleDeviceModel(ev.target.value)}
            >
              <option key="model-all" value={DEVICE_MODEL_ALL}>
                All models
              </option>
              <option key="model-unknown" value={DEVICE_MODEL_UNKNOWN}>
                Unknown models
              </option>
              {DEVICE_MODELS.map(m => (
                <option key={`model-${m}`} value={m}>
                  {m[0].toUpperCase() + m.substr(1)}
                </option>
              ))}
            </select>
          }
        />
      </GridItem>
      <GridItem xs={12} sm={6} lg={4} xl={3}>
        <InfoCard
          loading={loadingGoal}
          error={errorGoal}
          title="Goals Active"
          value={`${goalStats.active}`}
          icon="play_arrow"
          color={queryStats ? undefined : "primary"}
          description={`${goalStats.achievements} goals achieved`}
        />
      </GridItem>
      {loadingQuery ? (
        <GridItem xs={12}>
          <LinearProgress className={classes.progress} />
        </GridItem>
      ) : (
        queryStats && (
          <React.Fragment>
            <GridItem xs={12} xl={6}>
              <Card chart>
                <CardHeader color="info">
                  <ChartistGraph
                    className="ct-chart"
                    type="Line"
                    options={DEFAULT_CHART_OPTIONS}
                    data={sessionsChartData()}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={dashClasses.cardTitle}>Number of sessions</h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={6}>
              <Card chart>
                <CardHeader color="info">
                  <ChartistGraph
                    className="ct-chart"
                    type="Line"
                    options={DEFAULT_CHART_OPTIONS}
                    data={newUsersChartData()}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={dashClasses.cardTitle}>New user signups</h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={6}>
              <Card chart>
                <CardHeader color="info">
                  <ChartistGraph
                    className="ct-chart"
                    type="Line"
                    options={DEFAULT_CHART_OPTIONS}
                    data={activeUsersChartData()}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={dashClasses.cardTitle}>Active users</h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} xl={6}>
              <Card chart>
                <CardHeader color="info">
                  <ChartistGraph
                    className="ct-chart"
                    type="Line"
                    options={DEFAULT_CHART_OPTIONS}
                    data={newDevicesChartData()}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={dashClasses.cardTitle}>
                    New device connections
                  </h4>
                  <p>White = additions, Red = removals</p>
                </CardBody>
              </Card>
            </GridItem>
          </React.Fragment>
        )
      )}
    </GridContainer>
  );
};

export default Reports;
