export const DEVICE_MODELS = [
  "go",
  "mini",
  "solo",
  "duo",
  "max",
  "quad",
  "elite"
];

export const PUSH_TYPE = {
  GENERAL_ANNOUNCEMENT: "general",
  FIRMWARE_UPDATE: "firmware"
};
