import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from "prop-types";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const InfoCard = ({
  loading,
  error,
  icon,
  title,
  value,
  description,
  fontSize,
  color,
  footerChildren
}) => {
  const classes = useStyles();
  return (
    <div>
      <Card style={{ minHeight: 72 }}>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>{icon}</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>{title}</p>
          {loading ? (
            <LinearProgress
              className={classes.progress}
              style={{ marginTop: 10 }}
            />
          ) : error ? (
            <Danger>
              <p>{error}</p>
            </Danger>
          ) : (
            <h3 className={classes.cardTitle} style={fontSize && { fontSize }}>
              {value || "No data"}
            </h3>
          )}
        </CardHeader>
        {(description || footerChildren) && !loading && !error && (
          <CardFooter stats>
            {description && <div className={classes.stats}>{description}</div>}
            {footerChildren}
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default InfoCard;

InfoCard.defaultProps = {
  icon: "content_copy",
  title: "no title",
  value: "no data",
  description: null,
  fontSize: null,
  color: "info",
  footerChildren: null
};

InfoCard.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  footerChildren: PropTypes.object
};
