/*!
=========================================================
* Material Dashboard React - v1.8.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Amplify from "aws-amplify";

import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// core components
import Admin from "layouts/Admin.js";
import Login from "layouts/Login.js";
import ForgotPassword from "layouts/ForgotPassword.js";
import AuthenticatedRoute from "./routesType/AuthenticatedRoute";
import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5
});

Amplify.configure({
  identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={hist}>
        <Switch>
          <AuthenticatedRoute path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
