import React from "react";
import BaseDialog from "../BaseDialog/BaseDialog";

export default function ConfirmDialog(props) {
  const { visible, title, message, onClose, onPositive, onNegative } = props;
  return (
    <BaseDialog
      isConfirmation
      visible={visible}
      title={title}
      message={message}
      onClose={onClose}
      onPositive={onPositive}
      onNegative={onNegative}
    />
  );
}
