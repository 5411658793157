import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-react/components/loginStyle.js";
import { Auth } from "aws-amplify";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";
import MessageDialog from "components/MessageDialog/MessageDialog.js";

const useStyles = makeStyles(styles);

function ForgotPassword(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [stepConfirmation, setStepConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = event => {
    let { name, value } = event.target;
    setError("");
    if (name === "email") {
      setEmail(value);
    } else if (name === "code") {
      setCode(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const handleSubmit = () => {
    setError("");
    if (!stepConfirmation) {
      // Step 1: Send email with validation code
      setLoading(true);
      Auth.forgotPassword(email)
        .then(data => {
          setLoading(false);
          setStepConfirmation(true);
          console.log("FORGOT SUCCESS", data);
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });
    } else {
      // Step 2: Enter validation code and new password
      if (!code) {
        setError("Please enter the validation code");
        return;
      }
      if (!newPassword) {
        setError("Please enter a new password");
        return;
      }
      if (!confirmNewPassword) {
        setError("Please confirm the new password");
        return;
      }
      if (newPassword !== confirmNewPassword) {
        setError("Passwords do not match");
        return;
      }
      setLoading(true);
      Auth.forgotPasswordSubmit(email, code, newPassword)
        .then(data => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });
    }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  return (
    <div className={classes.container}>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Joovv admin</h4>
      </CardHeader>
      <div className={classes.loginForm}>
        <CustomInput
          labelText="Username"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "email",
            onChange: handleChange,
            disabled: stepConfirmation
          }}
        />

        {stepConfirmation ? (
          <React.Fragment>
            <p>
              A validation code has been sent to your email address. Please use
              it below.
            </p>
            <CustomInput
              labelText="Validation Code"
              id="code"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "code",
                onChange: handleChange
              }}
            />
            <CustomInput
              labelText="New Password"
              id="newPassword"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                name: "newPassword",
                onChange: handleChange
              }}
            />
            <CustomInput
              labelText="Confirm New Password"
              id="confirmNewPassword"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                name: "confirmNewPassword",
                onChange: handleChange
              }}
            />
          </React.Fragment>
        ) : null}

        {error && (
          <Danger>
            <p>{error}</p>
          </Danger>
        )}

        <Button
          round
          color="primary"
          size="lg"
          onClick={handleSubmit}
          disabled={loading}
          className={classes.button}
        >
          {stepConfirmation ? "Confirm" : "Continue"}
        </Button>
      </div>
      <MessageDialog
        visible={Boolean(success)}
        onClose={navigateBack}
        title="Success"
        message="Your new password has been set."
      />
    </div>
  );
}

export default ForgotPassword;
