import { makeStyles } from "@material-ui/core/styles";

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  dynamicRow: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  title: {
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 20px"
    }
  },
  message: {
    margin: "0 0 10px 0",
    [theme.breakpoints.up("md")]: {
      margin: "0 20px 0 0"
    }
  },
  schedule: {
    marginEnd: "20px",
    minWidth: "170px"
  },
  dateInput: {
    marginEnd: "20px",
    minWidth: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "170px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px"
    }
  },
  progress: {
    marginTop: "60px",
    marginBottom: "20px"
  },
  loadMore: {
    marginTop: "16px",
    alignSelf: "center"
  },
  textButton: {
    "&:hover": {
      color: "#9c27b0",
      cursor: "pointer"
    },
    "&:first-child": {
      marginRight: "10px"
    }
  },
});
export default makeStyles(styles);
