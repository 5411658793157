import { createMuiTheme } from "@material-ui/core/styles";
import {
  primaryColor,
  dangerColor,
  warningColor,
  infoColor,
  successColor,
  grayColor
} from "assets/jss/material-dashboard-react";

export default createMuiTheme({
  palette: {
    primary: {
      main: primaryColor[0]
    },
    error: {
      main: dangerColor[0]
    },
    warning: {
      main: warningColor[0]
    },
    info: {
      main: infoColor[0]
    },
    success: {
      main: successColor[0]
    },
    grey: {
      main: grayColor[0]
    }
  }
});
