import { container } from "assets/jss/material-dashboard-react.js";

const loginStyle = theme => ({
  container: {
    ...container,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "300px"
  },
  loginForm: {
    width: "350px",
    textAlign: "center"
  },
  button: {
    marginTop: "30px",
    width: "220px"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    width: "330px",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

export default loginStyle;
