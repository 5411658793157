import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import Dropdown from "components/Dropdown/Dropdown.js";
import Table from "components/Table/Table.js";
import MessageDialog from "components/MessageDialog/MessageDialog.js";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.js";
import api from "../../Services/Api";
import useStyles from "../commonStyles.js";

const TYPES = [
  {
    id: 0,
    label: "Liberta",
    dto: "liberta",
    modules: [
      {
        id: 0,
        label: "STM",
        dto: "stm",
        models: [
          { id: 0, label: "Go", dto: "go" },
          { id: 1, label: "Solo/Mini", dto: "solo" }
        ]
      },
      {
        id: 1,
        label: "BGM",
        dto: "bgm",
        models: [
          { id: 0, label: "Mini", dto: "mini" },
          { id: 1, label: "Solo", dto: "solo" },
          { id: 2, label: "Go", dto: "go" }
        ]
      }
    ]
  }
];

export default function Firmware() {
  const classes = useStyles();
  const initialFormErrors = { type: "", model: "", version: "", file: "" };

  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [type, setType] = useState("");
  const [moduleType, setModuleType] = useState("");
  const [model, setModel] = useState("");
  const [version, setVersion] = useState("");
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [modal, setModal] = useState(null);
  const [deleteFirmware, setDeleteFirmware] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [firmwares, setFirmwares] = useState([]);
  const [firmwaresError, setFirmwaresError] = useState("");
  const [loadingFirmware, setLoadingFirmware] = useState(false);
  const [canRefresh, setCanRefresh] = useState(false);

  useEffect(() => {
    fetchFirmwares();
  }, []);

  function fetchFirmwares() {
    setLoadingFirmware(true);
    return api.getAllFirmwareVersions().then(res => {
      setLoadingFirmware(false);
      if (res.ok) {
        let { data } = res;
        setFirmwares([...firmwares, ...data]);
      } else {
        setFirmwaresError(`ERROR: ${res.data || "Something went wrong"}`);
      }
    });
  }

  function handleChangeType(type) {
    setType(type);
    setModuleType("");
    setModel("");
    setFormErrors({ ...formErrors, type: "" });
  }

  function handleChangeModuleType(moduleType) {
    setModuleType(moduleType);
    setModel("");
    setFormErrors({ ...formErrors, moduleType: "" });
  }

  function handleChangeModel(model) {
    setModel(model);
    setFormErrors({ ...formErrors, model: "" });
  }

  function handleChangeInput(ev, setter) {
    const { name, value } = ev.target;
    setter(value);
    setFormErrors({ ...formErrors, [name]: "" });
  }

  function handleFileInput(ev) {
    const { value, files } = ev.target;
    setFilename(value);
    setFile(files[0]);
    setFormErrors({ ...formErrors, file: "" });
  }

  function handleButton() {
    let errors = {};
    if (!type) {
      errors.type = "Type is required";
    }
    if (!moduleType) {
      errors.moduleType = "Module type is required";
    }
    if (!model) {
      errors.model = "Model is required";
    }
    if (!version) {
      errors.version = "Version is required";
    }
    if (!file) {
      errors.file = "File is required";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors({ ...formErrors, ...errors });
      return;
    }
    setFormErrors(initialFormErrors);
    handleUploadFirmware();
  }

  function handleUploadFirmware() {
    setLoadingButton(true);
    return api
      .addFirmware({
        deviceType: type && type.dto,
        moduleType: moduleType && moduleType.dto,
        deviceModel: model && model.dto,
        version,
        file
      })
      .then(res => {
        setLoadingButton(false);
        setModal({
          title: res.ok ? "Success" : "Error",
          message: res.ok
            ? "Firmware uploaded successfully"
            : res.data || "Something went wrong"
        });
        if (res.ok) {
          setCanRefresh(true);
        }
      });
  }

  function handleDeleteFirmware(fw) {
    setDeleteFirmware(fw);
    setConfirmModal({
      title: "Confirm delete",
      message: "Are you sure you want to delete this firmware?"
    });
  }

  function confirmDeleteModal() {
    const {
      deviceType,
      moduleType,
      deviceModel,
      version,
      path
    } = deleteFirmware;
    return api
      .deleteFirmware({ deviceType, moduleType, deviceModel, version, path })
      .then(res => {
        setModal({
          title: res.ok ? "Success" : "Error",
          message: res.ok
            ? "Firmware deleted successfully"
            : res.data || res.problem || "Something went wrong"
        });
        if (res.ok) {
          setCanRefresh(true);
        }
      });
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Firmware Management</h4>
        <p className={classes.cardCategoryWhite}>
          Upload new firmware files to be used in the mobile app
        </p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <h5>Upload a new firmware version</h5>
          </GridItem>
          <GridItem xs={12} lg={8} xl={6}>
            <div className={classes.dynamicRow}>
              <Dropdown
                label={type ? type.label : "Select device type"}
                data={TYPES}
                onChange={handleChangeType}
                error={formErrors.type}
              />
              <Dropdown
                label={moduleType ? moduleType.label : "Select module type"}
                data={type ? type.modules : []}
                onChange={handleChangeModuleType}
                error={formErrors.moduleType}
              />
              <Dropdown
                label={model ? model.label : "Select device model"}
                data={moduleType ? moduleType.models : []}
                onChange={handleChangeModel}
                error={formErrors.model}
              />
              <CustomInput
                labelText="Version"
                id="version"
                error={Boolean(formErrors.version)}
                formControlProps={{
                  fullWidth: true,
                  className: classes.title
                }}
                inputProps={{
                  name: "version",
                  onChange: ev => handleChangeInput(ev, setVersion),
                  value: version,
                  placeholder: "",
                  error: Boolean(formErrors.version)
                }}
              />
            </div>
          </GridItem>
          <GridItem xs={12} lg={8} xl={6}>
            <div className={classes.dynamicRow}>
              <CustomInput
                labelText=""
                id="file"
                error={Boolean(formErrors.file)}
                formControlProps={{
                  fullWidth: true,
                  className: classes.title
                }}
                inputProps={{
                  name: "file",
                  type: "file",
                  onChange: handleFileInput,
                  value: filename,
                  placeholder: "",
                  error: Boolean(formErrors.file)
                }}
              />
              <Button
                round
                color="primary"
                onClick={handleButton}
                loading={loadingButton}
              >
                Upload firmware
              </Button>
            </div>
          </GridItem>
          {loadingFirmware ? (
            <GridItem xs={12}>
              <LinearProgress className={classes.progress} />
            </GridItem>
          ) : (
            <React.Fragment>
              <GridItem xs={12} container direction="column" justify="center">
                <h5>Past firmware versions</h5>
                {firmwaresError ? (
                  <Danger>{firmwaresError}</Danger>
                ) : firmwares.length > 0 ? (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "Device Type",
                      "Module Type",
                      "Device Model",
                      "Version",
                      "File path (AWS S3)",
                      "Actions"
                    ]}
                    tableData={firmwares.map(f => [
                      f.deviceType,
                      f.moduleType,
                      f.deviceModel,
                      f.version,
                      f.path,
                      <span
                        key={`${f.path}`}
                        className={classes.textButton}
                        onClick={() => handleDeleteFirmware(f)}
                      >
                        Delete
                      </span>
                    ])}
                  />
                ) : (
                  <p>There haven&apos;t been any firmware versions added.</p>
                )}
              </GridItem>
            </React.Fragment>
          )}
        </GridContainer>
      </CardBody>
      <MessageDialog
        visible={Boolean(modal)}
        onClose={() => (canRefresh ? window.location.reload() : setModal(null))}
        title={modal && modal.title}
        message={modal && modal.message}
      />
      <ConfirmDialog
        visible={Boolean(confirmModal)}
        title={confirmModal && confirmModal.title}
        message={confirmModal && confirmModal.message}
        onPositive={() => {
          setConfirmModal(null);
          confirmDeleteModal();
        }}
        onNegative={() => {
          setConfirmModal(null);
        }}
      />
    </Card>
  );
}
