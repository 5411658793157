import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import InfoCard from "components/InfoCard/InfoCard.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import api from "../../Services/Api";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function UserDetailed(props) {
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState({
    email: null,
    firstName: null,
    lastName: null,
    username: null,
    signupDate: null,
    lastLoginDate: null,
    countOfSessions: null,
    lastSessionDate: null,
    goalSet: null,
    devicesPaired: []
  });

  const [error, setError] = useState({
    userError: null,
    goalError: null,
    sessionsError: null
  });

  const getFormattedDate = date => {
    return new Date(date).toUTCString();
  };

  const getUserInfo = async ({ username: searchUsername }) => {
    const user = await api
      .getCognitoUser(searchUsername)
      .then(res => res.data.result.user[0])
      .catch(err => {
        alert(err);
        return { error: err };
      });

    if (!user) {
      setError({
        ...error,
        userError: `Invalid username: ${searchUsername}`
      });
    } else if (user.error) {
      setError(user.error);
      alert(user.error);
    } else {
      const {
        email,
        name: firstName,
        familyName: lastName,
        username,
        createDate,
        lastLoginDate,
        devicesPaired
      } = user;

      const devicesPairedFormatted = devicesPaired
        ? devicesPaired.map(item =>
            item === ""
              ? "Unknown model"
              : item.charAt(0).toUpperCase() + item.slice(1)
          )
        : [];

      const signupDate = getFormattedDate(createDate);

      const loginDate = lastLoginDate && getFormattedDate(lastLoginDate);

      const goal = await api
        .getUserGoal(username)
        .then(res => {
          console.log("goal", res);
          if (res.data.length === 0) return "No";
          else return "Yes";
        })
        .catch(err => {
          setError({
            ...error,
            goalError: err
          });
          return null;
        });

      const sessions = await api
        .getUserSessions(username)
        .then(res => {
          console.log("session", res);
          return res.data;
        })
        .catch(err => {
          setError({
            ...error,
            sessionsError: err
          });
          return null;
        });

      const lastSessionDate =
        !sessions || !sessions.length
          ? "No sessions"
          : getFormattedDate(
              moment.unix(Math.max.apply(Math, sessions.map(s => s.timestamp)))
            );

      const countOfSessions = sessions.length.toString();

      setUserInfo({
        email,
        firstName,
        lastName,
        username,
        signupDate,
        lastLoginDate: loginDate,
        countOfSessions,
        lastSessionDate,
        goalSet: goal,
        devicesPaired: devicesPairedFormatted
      });
    }
  };

  useEffect(() => {
    const {
      match: { params: username }
    } = props;
    getUserInfo(username);
  }, []);

  const {
    email,
    firstName,
    lastName,
    username,
    signupDate,
    lastLoginDate,
    countOfSessions,
    lastSessionDate,
    goalSet,
    devicesPaired
  } = userInfo;

  const { userError, goalError, sessionsError } = error;

  if (userError)
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <InfoCard
              title="Error"
              value={userError}
              icon="error"
              color="danger"
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  else
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Email"
              value={email}
              icon="alternate_email"
              fontSize={16}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard title="First name" value={firstName} icon="person" />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard title="Last name" value={lastName} icon="person" />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Username"
              value={username}
              icon="account_circle"
              fontSize={13}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Signup date"
              value={signupDate}
              icon="how_to_reg"
              fontSize={16}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Last login date"
              value={lastLoginDate}
              icon="login"
              fontSize={16}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Last session date"
              value={sessionsError || lastSessionDate}
              icon="emoji_objects"
              color={sessionsError ? "danger" : "info"}
              fontSize={16}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Count of sessions"
              value={sessionsError || countOfSessions}
              icon="emoji_objects"
              color={sessionsError ? "danger" : "info"}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoCard
              title="Goal set"
              value={goalError || goalSet}
              icon="add_task"
              color={goalError ? "danger" : "info"}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Device(s) Paired</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Device type"]}
                  tableData={devicesPaired}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
}
