import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function UsersTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const { tableHead, tableData, tableHeaderColor, userNames } = props;

  const onUserClick = username => {
    history.push(`/admin/user/${username}`);
  };

  return (  
    <div className={classes.tableResponsive}>   
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell
                className={classes.tableCell + " " + classes.tableHeadCell}
              />
              <TableCell
                className={classes.tableCell + " " + classes.tableHeadCell}
              />
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {
            return (
              <TableRow
                key={key}
                className={classes.tableBodyRow}
                style={{ cursor: "pointer", position: "relative" }}
                onClick={() => onUserClick(userNames[key])}
              >
                <TableCell className={classes.tableCell} key={key}>
                  {key + 1}
                </TableCell>
                <TableCell />
                {item.map((prop, key) => (
                  <TableCell className={classes.tableCell} key={key}>
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

UsersTable.defaultProps = {
  tableHeaderColor: "gray",
  linkTo: null,
  userNames: []
};

UsersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ),
  linkTo: PropTypes.string,
  userNames: PropTypes.arrayOf(PropTypes.string)
};
