/*eslint-disable*/
import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { DateTimePicker } from "@material-ui/pickers";
import Delete from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../Services/Api";
import Danger from "components/Typography/Danger";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Dropdown from "components/Dropdown/Dropdown";
import Table from "components/Table/Table";
import MessageDialog from "components/MessageDialog/MessageDialog";
import AutocompleteMulti from "components/AutocompleteMulti/AutocompleteMulti";
import useStyles from "../commonStyles.js";
import { PUSH_TYPE } from "../../constants";

const PAGE_SIZE_SCHEDULED = 10;
const PAGE_SIZE_REGULAR = 20;

const TYPES = [
  {
    id: 0,
    label: "General announcements",
    dto: PUSH_TYPE.GENERAL_ANNOUNCEMENT
  },
  { id: 1, label: "Firwmare update", dto: PUSH_TYPE.FIRMWARE_UPDATE }
];

const DATE_FORMAT = "M/D/Y h:mma";

export default function Notifications() {
  const classes = useStyles();
  const initialFormErrors = { title: "", message: "" };

  const [type, setType] = useState(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [deletingIds, setDeletingIds] = useState([]);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [modal, setModal] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [notificationsPage, setNotificationsPage] = useState({
    hasMore: true,
    loading: false,
    timestamp: moment().unix()
  });
  const [notificationsError, setNotificationsError] = useState("");

  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [scheduledPage, setScheduledPage] = useState({
    hasMore: true,
    loading: false,
    timestamp: moment().unix()
  });
  const [scheduledError, setScheduledError] = useState("");
  const [loadingNotifications, setLoadingNotifications] = useState(true);

  useEffect(() => {
    let historyPromise = fetchMoreNotifications();
    let scheduledPromise = fetchMoreScheduledNotifications();
    Promise.all([historyPromise, scheduledPromise]).then(() => {
      setLoadingNotifications(false);
    });
  }, []);

  function fetchMoreNotifications() {
    setNotificationsPage({ ...notificationsPage, loading: true });
    return api
      .getPushHistory({
        pageSize: PAGE_SIZE_REGULAR,
        timestamp: notificationsPage.timestamp
      })
      .then(res => {
        setNotificationsPage({ ...notificationsPage, loading: false });
        if (res.ok) {
          let { data } = res;
          setNotifications([...notifications, ...data]);
          setNotificationsPage({
            hasMore: data.length === PAGE_SIZE_REGULAR,
            timestamp: data.length
              ? data[data.length - 1].timestamp - 1
              : notificationsPage.timestamp
          });
        } else {
          setNotificationsError(`ERROR: ${res.data || "Something went wrong"}`);
        }
      });
  }

  function fetchMoreScheduledNotifications() {
    setScheduledPage({ ...scheduledPage, loading: true });
    return api
      .getPushScheduled({
        pageSize: PAGE_SIZE_SCHEDULED,
        timestamp: scheduledPage.timestamp
      })
      .then(res => {
        setScheduledPage({ ...scheduledPage, loading: false });
        if (res.ok) {
          let { data } = res;
          setScheduledNotifications([...scheduledNotifications, ...data]);
          setScheduledPage({
            hasMore: data.length === PAGE_SIZE_SCHEDULED,
            timestamp: data.length
              ? data[data.length - 1].timestamp
              : scheduledPage.timestamp
          });
        } else {
          setScheduledError(`ERROR: ${res.data || "Something went wrong"}`);
        }
      });
  }

  function sortAscending(notifications) {
    notifications.sort((a, b) => a.timestamp - b.timestamp);
    return notifications;
  }

  function sortDescending(notifications) {
    notifications.sort((a, b) => b.timestamp - a.timestamp);
    return notifications;
  }

  function handleTypeChange(type) {
    switch (type.id) {
      case 0:
        setTitle("General announcement");
        setMessage("");
        break;
      case 1:
        setTitle("New firmware version available");
        setMessage(
          "Head over to the Devices screen to update your Joovv to the latest version"
        );
        break;
    }
    setType(type);
    setFormErrors(initialFormErrors);
  }

  function handleInputChange(ev) {
    let { name, value } = ev.target;
    let setInvoker = { title: setTitle, message: setMessage };
    setInvoker[name](value);
    setFormErrors({ ...formErrors, [name]: "" });
  }

  function handleDelete(id) {
    setDeletingIds([...deletingIds, id]);
    api.deletePushScheduled({ id }).then(res => {
      setDeletingIds(deletingIds.filter(i => i !== id));
      if (res.ok) {
        setScheduledNotifications(
          scheduledNotifications.filter(n => n._id !== id)
        );
      } else {
        setModal({
          title: "Error",
          message: res.data || "Something went wrong"
        });
      }
    });
  }

  function handleButton() {
    let errors = {};
    if (!type) {
      errors.type = "Type is required";
    }
    if (!title) {
      errors.title = "Title is required";
    }
    if (!message) {
      errors.message = "Message is required";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors({ ...formErrors, ...errors });
      return;
    }
    if (scheduledTime) {
      handleSchedule();
    } else {
      handleSend();
    }
  }

  function handleSchedule() {
    setLoadingButton(true);
    api
      .schedulePush({
        type: type && type.dto,
        title,
        message,
        userIds: selectedUserIds,
        timestamp: moment(scheduledTime).unix()
      })
      .then(res => {
        setLoadingButton(false);
        setModal({
          title: res.ok ? "Success" : "Error",
          message: res.ok
            ? "The push notification was scheduled successfully"
            : res.data || "Something went wrong"
        });
        if (res.ok) {
          clearInputs();
          setScheduledNotifications(
            sortAscending([res.data, ...scheduledNotifications])
          );
        }
      });
  }

  function handleSend() {
    setLoadingButton(true);
    api
      .sendPush({
        type: type && type.dto,
        title,
        message,
        userIds: selectedUserIds
      })
      .then(res => {
        setLoadingButton(false);
        setModal({
          title: res.ok ? "Success" : "Error",
          message: res.ok
            ? "The push notification was sent successfully"
            : res.data || "Something went wrong"
        });
        if (res.ok) {
          clearInputs();
          setNotifications(sortDescending([res.data, ...notifications]));
        }
      });
  }

  function clearInputs() {
    setType(null);
    setTitle("");
    setMessage("");
    setScheduledTime(null);
    setFormErrors(initialFormErrors);
  }

  function buildViewModel(notif, scheduled) {
    let id = notif._id;
    let type;
    switch (notif.type) {
      case PUSH_TYPE.FIRMWARE_UPDATE:
        type = "Firmware update";
        break;
      case PUSH_TYPE.GENERAL_ANNOUNCEMENT:
        type = "General announcement";
        break;
      default:
        type = "Other";
        break;
    }
    let date = moment.unix(notif.timestamp).format(DATE_FORMAT);
    let users =
      notif.userIds && notif.userIds.length
        ? (notif.userEmails && notif.userEmails.join(" ") || "")
        : "All users";
    let vm = [type, notif.title, notif.message, users, date];
    if (scheduled) {
      vm.push(
        <Button
          justIcon
          round
          color="primary"
          onClick={() => handleDelete(id)}
          loading={deletingIds.includes(id)}
        >
          <Delete />
        </Button>
      );
    }
    return vm;
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Notifications</h4>
        <p className={classes.cardCategoryWhite}>
          Send push notifications to all users of the mobile app
        </p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <h5>Send a new notification</h5>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.dynamicRow}>
              <Dropdown
                label={type ? type.label : "Select a type"}
                data={TYPES}
                onChange={handleTypeChange}
                error={formErrors.type}
              />
              <AutocompleteMulti
                labelText="Specific users (optional)"
                id="users"
                canReload={Boolean(type)}
                formControlProps={{
                  fullWidth: true,
                  className: classes.title
                }}
                autocompleteProps={{
                  key: `autocomplete${type && type.dto}`,
                  noOptionsText: `${
                    !type ? "Select a type to search users" : "No users found"
                  }`,
                  getOptionDisabled: option =>
                    !option.fcmToken ||
                    (type &&
                      option.notifications &&
                      !option.notifications[type.dto])
                }}
                onChange={(ev, arr) => setSelectedUserIds(arr.map(o => o.id))}
              />
            </div>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.dynamicRow}>
              <CustomInput
                labelText="Notification title"
                id="title"
                error={Boolean(formErrors.title)}
                formControlProps={{
                  fullWidth: true,
                  className: classes.message
                }}
                inputProps={{
                  name: "title",
                  onChange: handleInputChange,
                  value: title,
                  placeholder: "",
                  error: Boolean(formErrors.title)
                }}
              />
            </div>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.dynamicRow}>
              <CustomInput
                labelText="Notification message"
                id="message"
                error={Boolean(formErrors.message)}
                formControlProps={{
                  fullWidth: true,
                  className: classes.message
                }}
                inputProps={{
                  name: "message",
                  onChange: handleInputChange,
                  value: message,
                  placeholder: "",
                  error: Boolean(formErrors.message)
                }}
              />
              <DateTimePicker
                disablePast
                clearable
                label="Schedule time"
                inputVariant="outlined"
                format={DATE_FORMAT}
                value={scheduledTime}
                onChange={setScheduledTime}
                className={classes.schedule}
                initialFocusedDate={moment()
                  .add(1, "hour")
                  .startOf("hour")}
              />
              <Button
                round
                color="primary"
                onClick={handleButton}
                loading={loadingButton}
              >
                {scheduledTime ? "Schedule" : "Send Now"}
              </Button>
            </div>
          </GridItem>
          {loadingNotifications ? (
            <GridItem xs={12}>
              <LinearProgress className={classes.progress} />
            </GridItem>
          ) : (
            <Fragment>
              <GridItem xs={12} container direction="column" justify="center">
                <h5>Scheduled Notifications</h5>
                {scheduledError ? (
                  <Danger>{scheduledError}</Danger>
                ) : scheduledNotifications.length > 0 ? (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "Type",
                      "Title",
                      "Message",
                      "Users",
                      "Scheduled",
                      "Actions"
                    ]}
                    tableData={scheduledNotifications.map(n =>
                      buildViewModel(n, true)
                    )}
                  />
                ) : (
                  <p>There are no scheduled notifications to be sent.</p>
                )}
                {scheduledPage.hasMore ? (
                  <Button
                    round
                    color="white"
                    onClick={fetchMoreScheduledNotifications}
                    loading={scheduledPage.loading}
                    className={classes.loadMore}
                  >
                    Load More
                  </Button>
                ) : null}
              </GridItem>
              <GridItem xs={12} container direction="column" justify="center">
                <h5>Notifications History</h5>
                {notificationsError ? (
                  <Danger>{notificationsError}</Danger>
                ) : notifications.length > 0 ? (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Type", "Title", "Message", "Users", "Sent"]}
                    tableData={notifications.map(n => buildViewModel(n))}
                  />
                ) : (
                  <p>There were no notifications sent to the users.</p>
                )}
                {notificationsPage.hasMore ? (
                  <Button
                    round
                    color="white"
                    onClick={fetchMoreNotifications}
                    loading={notificationsPage.loading}
                    className={classes.loadMore}
                  >
                    Load More
                  </Button>
                ) : null}
              </GridItem>
            </Fragment>
          )}
        </GridContainer>
      </CardBody>
      <MessageDialog
        visible={Boolean(modal)}
        onClose={() => setModal(null)}
        title={modal && modal.title}
        message={modal && modal.message}
      />
    </Card>
  );
}
