import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Api from "../../Services/Api";

const useStyles = makeStyles(styles);

const AutocompleteMulti = props => {
  const {
    id,
    canReload,
    autocompleteProps,
    onChange,
    labelText,
    labelProps,
    formControlProps,
    inputProps,
    success,
    error
  } = props;

  const [searchTerm, setSearchTerm] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let active = true;

    (async () => {
      const data = await tryFetchUsers(searchTerm);
      if (active && data) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchTerm]);

  React.useEffect(() => {
    let active = true;
    if (open) {
      (async () => {
        const data = await tryFetchUsers(searchTerm);
        if (active && data) {
          setOptions(data);
        }
      })();
    } else {
      setOptions([]);
    }

    return () => {
      active = false;
    };
  }, [open]);

  let keyboardTimer = null;
  const setSearchTermDelayed = value => {
    clearTimeout(keyboardTimer);
    keyboardTimer = setTimeout(() => setSearchTerm(value), 500);
  };

  const tryFetchUsers = async searchTerm => {
    if (canReload) {
      setLoading(true);
      const response = await Api.getUsersForPush({ searchTerm });
      setLoading(false);
      if (response.ok) {
        return response.data;
      }
    }
    return null;
  };

  const classes = useStyles();
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl {...formControlProps}>
      <Autocomplete
        {...autocompleteProps}
        onChange={onChange}
        multiple
        id={id}
        classes={{
          root: marginTop
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        getOptionLabel={option => option.email}
        getOptionSelected={(option, value) => option.email === value.email}
        onInputChange={(ev, value) => setSearchTermDelayed(value)}
        noOptionsText={
          loading ? "Loading..." : autocompleteProps.noOptionsText || ""
        }
        renderInput={params => (
          <TextField
            {...params}
            label={labelText}
            InputLabelProps={{
              className: `${classes.labelRoot}${labelClasses}`,
              htmlFor: id,
              ...labelProps
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            fullWidth
            variant="standard"
            placeholder={inputProps.placeholder}
          />
        )}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
};

AutocompleteMulti.propTypes = {
  id: PropTypes.string,
  autocompleteProps: PropTypes.object,
  onChange: PropTypes.func,
  canReload: PropTypes.bool,
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};

AutocompleteMulti.defaultProps = {
  autocompleteProps: {},
  formControlProps: {},
  inputProps: {}
};

export default AutocompleteMulti;
