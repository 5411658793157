import { Auth } from "aws-amplify";
// a library to wrap and simplify api calls
import apisauce from "apisauce";

const create = (baseURL = process.env.REACT_APP_API_URL) => {
  const getJwtToken = () => {
    return Auth.currentSession().then(user => {
      return user.accessToken.jwtToken;
    });
  };

  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache"
    },
    // 10 second timeout...
    timeout: 10000
  });

  const authorize = () =>
    getJwtToken().then(token => {
      api.setHeader("Authorization", token);
      return api;
    });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const getCognitoUsers = data =>
    authorize().then(() => api.post("/cognito/users", { data }));

  const getCognitoUser = data =>
    authorize().then(() => api.post("/cognito/user", { data }));

  const deleteCognitoUser = data =>
    authorize().then(() => api.post("/cognito/user/delete", { data }));

  const updateCognitoUser = ({ username, firstName, lastName }) =>
    authorize().then(api =>
      api.post("/cognito/user/update", { username, firstName, lastName })
    );

  const updateCognitoUserPassword = ({ username, password }) =>
    authorize().then(api =>
      api.post("/cognito/user/password", { username, password })
    );

  const createCognitoUser = ({ email, firstName, lastName }) =>
    authorize().then(api =>
      api.post("/cognito/user/create", { email, firstName, lastName })
    );

  const sendPush = ({ type, title, message, userIds }) =>
    authorize().then(api =>
      api.post("/push/send", { type, title, message, userIds })
    );

  const schedulePush = ({ type, title, message, userIds, timestamp }) =>
    authorize().then(api =>
      api.post("/push/schedule", { type, title, message, userIds, timestamp })
    );

  const getUsersForPush = ({ searchTerm }) =>
    authorize().then(api => api.get("/push/users", { searchTerm }));

  const getPushHistory = ({ pageSize, timestamp }) =>
    authorize().then(api => api.get("/push/history", { pageSize, timestamp }));

  const getPushScheduled = ({ pageSize, timestamp }) =>
    authorize().then(api =>
      api.get("/push/scheduled", { pageSize, timestamp })
    );

  const deletePushScheduled = ({ id }) =>
    authorize().then(api => api.delete("/push/scheduled", { id }));

  const getUserGoal = username =>
    authorize().then(api => api.get("/goal/get", { user: username }));

  const getUserSessions = username =>
    authorize().then(api => api.get("/session/get", { user: username }));

  const getAllFirmwareVersions = () =>
    authorize().then(api => api.get("/firmware/all"));

  const addFirmware = ({
    deviceType,
    moduleType,
    deviceModel,
    version,
    file
  }) =>
    authorize().then(api => {
      api.setHeader("Content-Type", "multipart/form-data");

      const formData = new FormData();
      formData.append("deviceType", deviceType);
      formData.append("moduleType", moduleType);
      formData.append("deviceModel", deviceModel);
      formData.append("version", version);
      formData.append("firmware", file);

      return api.post("/firmware", formData);
    });

  const deleteFirmware = ({
    deviceType,
    moduleType,
    deviceModel,
    version,
    path
  }) =>
    authorize().then(api =>
      api.delete("/firmware", {
        deviceType,
        moduleType,
        deviceModel,
        version,
        path
      })
    );

  const getUserStats = (startDate, endDate) =>
    authorize().then(api =>
      api.get("/stats/users", startDate && endDate && { startDate, endDate })
    );

  const getSessionStats = (startDate, endDate) =>
    authorize().then(api =>
      api.get("/stats/sessions", startDate && endDate && { startDate, endDate })
    );

  const getDeviceStats = (startDate, endDate, model) =>
    authorize().then(api =>
      api.get("/stats/devices", { startDate, endDate, model })
    );

  const getGoalStats = (startDate, endDate) =>
    authorize().then(api =>
      api.get("/stats/goals", startDate && endDate && { startDate, endDate })
    );

  const getRangeStats = (startDate, endDate) =>
    authorize().then(api => api.get("/stats/range", { startDate, endDate }));

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getCognitoUsers,
    getCognitoUser,
    deleteCognitoUser,
    updateCognitoUser,
    updateCognitoUserPassword,
    createCognitoUser,
    sendPush,
    schedulePush,
    getUsersForPush,
    getPushHistory,
    getPushScheduled,
    deletePushScheduled,
    getUserGoal,
    getUserSessions,
    getAllFirmwareVersions,
    addFirmware,
    deleteFirmware,
    getUserStats,
    getSessionStats,
    getDeviceStats,
    getGoalStats,
    getRangeStats
  };
};

const instance = create();
export default instance;
