import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function MessageDialog(props) {
  const { isConfirmation, visible, title, message, onClose, onPositive, onNegative } = props;
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isConfirmation ? (
          <React.Fragment>
            <Button onClick={onNegative} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={onPositive} color="primary" autoFocus>
              Yes
            </Button>
          </React.Fragment>
        ) : (
          <Button onClick={onClose} color="primary" autoFocus>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
