import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const AuthenticatedRoute = ({ ...props }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const getCurrentUser = async () => {
    try {
      const currentUserObj = await Auth.currentAuthenticatedUser();
      setCurrentUser(currentUserObj);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  if (loading) return null;
  if (!currentUser) return <Redirect to="/login" />;
  return <Route {...props} />;
};
export default AuthenticatedRoute;
