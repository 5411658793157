import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-react/components/loginStyle.js";
import { Auth } from "aws-amplify";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";

const useStyles = makeStyles(styles);

function Login(props) {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  const handleChange = event => {
    if (event.target.name === "email") {
      setUser(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = () => {
    setDisabled(true);
    Auth.signIn(user, password)
      .then(user => {
        if (
          user.attributes["custom:isAdmin"] &&
          user.attributes["custom:isAdmin"] === "1"
        ) {
          props.history.push("/admin/list-users");
          return;
        }
        Auth.signOut();
        setDisabled(false);
        setError("Unauthorized");
      })
      .catch(err => {
        setDisabled(false);
        setError(err.message);
      });
  };

  const showForgotPassword = () => {
    props.history.push("/forgot-password");
  };

  return (
    <div className={classes.container}>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Joovv admin</h4>
      </CardHeader>
      <div className={classes.loginForm}>
        <CustomInput
          labelText="Username"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            name: "email",
            onChange: handleChange
          }}
        />
        <CustomInput
          labelText="Password"
          id="password"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "password",
            name: "password",
            onChange: handleChange
          }}
        />

        {error && (
          <Danger>
            <p>{error}</p>
          </Danger>
        )}

        <Button
          round
          color="primary"
          size="lg"
          onClick={handleSubmit}
          disabled={disabled}
          className={classes.button}
        >
          Log in
        </Button>

        <Button
          round
          color="white"
          size="lg"
          onClick={showForgotPassword}
          disabled={disabled}
          className={classes.button}
        >
          Forgot password
        </Button>
      </div>
    </div>
  );
}

export default Login;
